import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { actionUpdateVehicle } from "../../services/VehicleService";
import { actionCountryList } from "../../services/Common";
import Select from "react-select";
import { createCustomer, getPlaceList } from "../../services/CustomerService";

const tableLengthList = configDb.data.dataTableLength;

const AddresCustomer = ({ setActiveTab, customerDetails }) => {
  const [addressData, setAddressData] = useState({
    uuid: "",
    address: "",
    address1: "",
    country: "",
    zip_code: "",
    place: "",
    city: "",
  });

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [placeList, setPlaceList] = useState([]);

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getPlaceList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].radius_zonename,
          });
        }
        setPlaceList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (customerDetails) {
      setAddressData({
        uuid: customerDetails?.uuid,
        address: customerDetails?.address,
        address1: customerDetails?.address1,
        country: customerDetails?.country,
        zip_code: customerDetails?.zip_code,
        place: customerDetails?.place,
        city: customerDetails?.city,
      });
    }
  }, [customerDetails]);

  const handleCustomerForm = (e) => {
    e.preventDefault();
  

    let data = {
      address: addressData?.address,
      address1: addressData?.address1,
      country: addressData?.country,
      zip_code: addressData?.zip_code,
      place: addressData?.place,
      city: addressData?.city,
    };

    let updateData = {
      uuid: addressData?.uuid,
      address: addressData?.address,
      address1: addressData?.address1,
      country: addressData?.country,
      zip_code: addressData?.zip_code,
      place: addressData?.place,
      city: addressData?.city,
    };

    if (addressData?.uuid) {
      modifyCustomer(createCustomer, updateData);
    } else {
      modifyCustomer(createCustomer, data);
    }
  };

  const modifyCustomer = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("contact");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleCustomerForm}>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Address Line 1"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Address Line 1"
                          name={`address`}
                          onChange={(e) => {
                            setAddressData({
                              ...addressData,
                              address: e.target.value,
                            });
                          }}
                          value={addressData.address}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Address Line 2"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Address Line 2"
                          name={`address1`}
                          onChange={(e) => {
                            setAddressData({
                              ...addressData,
                              address1: e.target.value,
                            });
                          }}
                          value={addressData.address1}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"ZIP code"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          type="number"
                          placeholder="ZIP code"
                          name={`zip_code`}
                          onChange={(e) => {
                            setAddressData({
                              ...addressData,
                              zip_code: e.target.value,
                            });
                          }}
                          value={addressData.zip_code}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"City"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="City"
                          name={`city`}
                          onChange={(e) => {
                            setAddressData({
                              ...addressData,
                              city: e.target.value,
                            });
                          }}
                          value={addressData.city}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Select Country"}
                      </Label>
                      <Select
                        options={countryList}
                        value={countryList?.find(
                          (option) =>
                            option.value === Number(addressData.country)
                        )}
                        onChange={(selectedOption) =>
                          setAddressData((prevData) => ({
                            ...prevData,
                            country: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Country"}
                      />
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Select Place"}</Label>
                      <Select
                        options={placeList}
                        value={placeList?.find(
                          (option) => option.value === Number(addressData.place)
                        )}
                        onChange={(selectedOption) =>
                          setAddressData((prevData) => ({
                            ...prevData,
                            place: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Place"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddresCustomer;

import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { actionUpdateVehicle } from "../../services/VehicleService";
import { createCustomer } from "../../services/CustomerService";

const tableLengthList = configDb.data.dataTableLength;

const GeneralCustomer = ({
  setActiveTab,
  customerDetails,
  setCustomerUUId,
}) => {
  const [customerData, setCustomerData] = useState({
    uuid: "",
    name: "",
    vatNumber: "",
  });

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(() => {
    if (customerDetails) {
      setCustomerData({
        uuid: customerDetails?.uuid,
        name: customerDetails?.name || "",
        vatNumber: customerDetails?.vat_number || "",
      });
    }
  }, [customerDetails]);

  const handleCustomerForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!customerData.name) {
      errorObj.name = `Name is required.`;
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let data = {
      name: customerData.name,
      vat_number: customerData.vatNumber,
    };

    let updateData = {
      uuid: customerDetails?.uuid,
      name: customerData.name,
      vat_number: customerData.vatNumber,
    };

    if (customerDetails?.uuid) {
      modifyCustomer(createCustomer, updateData);
    } else {
      modifyCustomer(createCustomer, data);
    }
  };

  const modifyCustomer = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("address");
        setCustomerUUId(response.data.uuid);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleCustomerForm}>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-6">
                      <Label className="col-form-label">
                        {"Name"}
                        <span className={`text-danger ml-1`}>*</span>
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={customerData.name}
                          onChange={(e) => {
                            setCustomerData({
                              ...customerData,
                              name: e.target.value,
                            });
                          }}
                          name={`name`}
                          maxLength={50}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <span className="input-error">{errors.name}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">{"VAT Number"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id="vatNumber"
                          type="number"
                          placeholder="VAT Number"
                          name={`vatNumber`}
                          onChange={(e) => {
                            setCustomerData({
                              ...customerData,
                              vatNumber: e.target.value,
                            });
                          }}
                          value={customerData.vatNumber}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralCustomer;

import React from 'react'

const Distribution = () => {
  return (
    <div>
      Distribution
    </div>
  )
}

export default Distribution

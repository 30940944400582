import axiosInstance from "./AxiosInstance";

export function createCustomer(data) {
  return axiosInstance.post(`/create-customer`, data);
}

export function getCustomerList(data) {
  return axiosInstance.post(`/customer-list`, data);
}

export function deleteCustomer(data) {
  return axiosInstance.post(`/customer-delete`, data);
}

export function getcustomerDetails(data) {
  return axiosInstance.post(`/customer-details`, data);
}

export function getPlaceList(data) {
  return axiosInstance.post(`/area-view-list`, data);
}

export function getTripCustomerList(data) {
  return axiosInstance.post(`/trip/customer-list`, data);
}

export function getTripCustomerDetails(data) {
  return axiosInstance.post(`/trip/trip-details`, data);
}

export function getViewCustomerList(data) {
  return axiosInstance.post(`/customer-view-list`, data);
}

export function actioncustomerTripCreate(data) {
  return axiosInstance.post(`/trip/trip-update`, data);
}

export function destoryCustomerTripDetails(data) {
  return axiosInstance.post(`/trip/trip-details-delete`, data);
}



import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import {
  actionAlarmReports,
  getVitualOdometerReportList,
} from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";


const VirtualOdometerList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    getVitualOdometerReportList()
      .then((response) => {
        setAlarmsDetailsList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  const exportToExcel = () => {
    if (!alarmsDetailsList || alarmsDetailsList.length === 0) {
      toast.error("No data to export!");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(alarmsDetailsList);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "Vrirtual odometer.xlsx");
  };

  return (
    <div>
      <h1>Virtual Odometer Reports</h1>
      {alarmsDetailsList?.length > 0 ? (
        <>
          <div className="tablePage">
            <div className="container-fluid">
              <div className="tablePageMain mt-2">
                <div className>
                  <div className="logoimg">
                    <img src={Logo} alt="logoimg" />
                  </div>
                </div>
                <div className>
                  <div className="Content">
                    <div>
                      <h3>Virtual Odometer</h3>
                      <h6 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                        {" "}
                        Export Excel
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-table mt-4">
                <table className="table table-striped table-hover">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Vehicle </th>
                      <th scope="col">Manufacturer </th>
                      <th scope="col">Model</th>
                      <th scope="col">Virtual odometer (km) </th>
                      <th scope="col">Chronometer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alarmsDetailsList?.map((alarm) => {
                      return (
                        <tr>
                          <td>{alarm?.vehicle_name}</td>
                          <td>{alarm?.vehicle_make_model_year}</td>
                          <td>{alarm?.vehicle_model}</td>
                          <td>{alarm?.total_odometer}</td>
                          <td>{alarm?.trip_odometer}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>No alarm details available.</p>
      )}
    </div>
  );
};

export default VirtualOdometerList;

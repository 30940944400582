import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import { actionDelCompany } from "../../services/CompanyService";
import { getTripList, getTripTemplateList } from "../../services/TripService";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import plusIcon from "../../assets/images/plus-i.svg";
import EditTemplateTrip from "./EditTemplateTrip";

const tableLengthList = configDb.data.dataTableLength;

const TemplateTrip = (props) => {
  const allPermissionsList = useSelector((x) => x.permission.value);
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getFirstDayOfMonth = () => {
    const now = new Date();
    return `${now.getFullYear()}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-01`;
  };
  const [tripList, setTripList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [delModal, setDelModal] = useState(false);
  const [delCompany, setDelCompany] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicle, setVehicle] = useState("");
  const [AddTemplateStatus, setAddTemplateStatus] = useState(false);
  const [TemplateStatusData, setTemplateStatusData] = useState({});

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    vehicleId: vehicle.vehicleId,
    page: 1,
    search: "",
    endDate: getCurrentDate(),
    date: getFirstDayOfMonth(),
    is_reload: false,
  });

  const navigate = useNavigate();

  const handleTemplateStatus = (e, item) => {
    setAddTemplateStatus(true);
    setTemplateStatusData(item);
  };



  useEffect(() => {
    let data = params;
    getVehicleList(data)
      .then((response) => {
        response = response.data;
        let list = response.data.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.vehicleName,
        }));
        setVehicleList(list);
      })
      .catch((err) => {});
  }, [params]);

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getTripTemplateList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setTripList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "date") {
      paramsObj.page = 1;
      paramsObj.date = e.target.value;
    } else if (type === "endDate") {
      paramsObj.page = 1;
      paramsObj.endDate = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "vehicle") {
      paramsObj.vehicleId = e.value;
      paramsObj.page = 1;
    }
    setParams(paramsObj);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelCompany("");
    setDelModal(false);
  };

  const onDestroyCompany = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delCompany };
    actionDelCompany(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelCompany("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      {AddTemplateStatus ? (
        <EditTemplateTrip
          setAddTemplateStatus={setAddTemplateStatus}
          TemplateStatusData={TemplateStatusData}
        />
      ) : (
        <>
          <div className="inner-header py-3">
            <div className="left-block">
              <h5>Template Routes</h5>
            </div>
            <div className="right-block w-auto">
              <Button
                type={`button`}
                onClick={() => {
                  navigate("/dashboard");
                }}
                className={`add-btn btn  float-right`}
              >
                {`Add`}
                <i className="plus-circle">
                  <img src={plusIcon} alt="" className="plus-icons" />
                </i>
              </Button>
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row">
              <div className="row">
                <div className="col">
                  <div className="row company-section company-section-Area">
                    <div className="col-md-2">
                      <div className="row">
                        <div className="col">
                          <div className="selected-block selected-mobile">
                            <p>Show</p>
                            <select
                              className="form-select form-control-show"
                              aria-label="Events Only"
                              defaultValue={params.limit}
                              onChange={(e) => handleParams(e, "length")}
                            >
                              {tableLengthList &&
                                tableLengthList.map((item, i) => (
                                  <option value={item} key={i}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="selected-block selected-mobile">
                        <p>Search</p>
                        <div className="search-bar">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-table-row">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>{`Trip Name`}</th>
                    <th scope={`col`}>{`Code`}</th>
                    <th scope={`col`}>{`Steps`}</th>
                    <th scope={`col`}>{`Departure Place`}</th>
                    <th scope={`col`}>{`Arrival Place`}</th>
                    <th scope={`col`}>{`Duration`}</th>
                    <th scope={`col`}>{`Update Date`}</th>
                    <th scope={`col`}>{`User Name`}</th>

                    {allPermissionsList.findIndex(
                      (e) => e.name === "view_details_trip"
                    ) > -1 && <th scope={`col`}>{`Action`}</th>}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {tripList &&
                        tripList?.data?.map((item, i) => (
                          <tr key={i}>
                            <td style={{ cursor: "pointer" }}>
                              {item.tripName.trip_name}
                            </td>
                            <td style={{ cursor: "pointer" }}>-</td>
                            <td style={{ cursor: "pointer" }}>-</td>
                            <td style={{ cursor: "pointer" }}>
                              {item.tripName.source_place}
                            </td>
                            <td style={{ cursor: "pointer" }}>
                              {item.tripName.destination_place}
                            </td>

                            <td style={{ cursor: "pointer" }}>
                              {item.tripName.total_duration} m
                            </td>
                            <td style={{ cursor: "pointer" }}>-</td>
                            <td style={{ cursor: "pointer" }}>
                              {JsonParse?.name}
                            </td>
                            {(allPermissionsList.findIndex(
                              (e) => e.name === "view_details_trip"
                            ) > -1 ||
                              allPermissionsList.findIndex(
                                (e) => e.name === "delete_trip"
                              ) > -1) && (
                              <td>
                                {allPermissionsList.findIndex(
                                  (e) => e.name === "view_details_trip"
                                ) > -1 && (
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      handleTemplateStatus(e, item)
                                    }
                                    className="btn btn-info add-btn-circle me-2"
                                  >
                                    <i className="bi bi-pencil-fill"></i>
                                  </button>
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                    </>
                  )}

                  {tripList && tripList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={
                          allPermissionsList.findIndex(
                            (e) => e.name === "modify_trip"
                          ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name === "delete_trip"
                          ) > -1
                            ? 8
                            : 8
                        }
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`DeleteRole`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this company?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyCompany}
            >{`Delete`}</button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TemplateTrip;

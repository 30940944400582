import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import AlarmsReports from "./AlarmsReports";
import CostFuelReport from "./CostFuelReport";
import DailySummaryReports from "./DailySummaryReports";
import DetailedCostReport from "./DetailedCostReport";
import DriverDiary from "./DriverDiary";
import DrivingBehaviorEvent from "./DrivingBehaviorEvent";
import FuelManagement from "./FuelManagement";
import PermenenceReports from "./PermenenceReports";
import RoundTripsReports from "./RoundTripsReports";
import RouteExecutionReport from "./RouteExecutionReport";
import VehicleDetailsReport from "./VehicleDetailsReport";
import VehicleTripsReport from "./VehicleTripsReport";
import VirtualOdometerReports from "./VirtualOdometerReports";
import VehicleStopReport from "./VehicleStopReport";

const Reports = () => {
  const [activeTab, setActiveTab] = useState("alarms");
  const [driverDetails, setDriverDetails] = useState({});
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block">{/* <h5>Management</h5> */}</div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            // onClick={() => setAddDriverStatus(false)}
          >
            {/* <i class="bi bi-chevron-left"></i> Back */}
          </button>
        </div>
        <div className="tight-block"></div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${activeTab === "alarms" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("alarms");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-bell"></i> Alarms
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "cost_fuel" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("cost_fuel");
                }}
              >
                <span data-href="#tab-1">
                  {" "}
                  <i className="bi bi-fuel-pump me-1"></i>
                  Cost and fuel consumption per miles and and time
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "daily_summary" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("daily_summary");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-calendar-event me-1"></i>Daily Summary
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "detailed_cost" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("detailed_cost");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-file-earmark"></i> Detailed costs
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "driver_diary" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driver_diary");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-journal"></i> Driver Diary
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "driver_event" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driver_event");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-person"></i> Driver Behavior events{" "}
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "fuel_managemnet" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("fuel_managemnet");
                }}
              >
                <span data-href="#tab-1">
                  {" "}
                  <i className="bi bi-fuel-pump-fill"></i> Fuel Management
                </span>
              </li>

              {/* <li
                className={`nav-link ${
                  activeTab === "Permanence" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("Permanence");
                }}
              >
                <span data-href="#tab-1">Permanence In Places</span>
              </li> */}

              <li
                className={`nav-link ${
                  activeTab === "roundtrips" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("roundtrips");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-arrow-repeat"></i> Round Trips
                </span>
              </li>

              <li
                className={`nav-link ${activeTab === "route" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("route");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-map"></i> Route Execution
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "vehicleDetails" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("vehicleDetails");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-file-earmark-bar-graph"></i> Vehicle
                  Details
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "vehicleTrip" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("vehicleTrip");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-car-front"></i> Vehicle Trips
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "vehicleStop" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("vehicleStop");
                }}
              >
                <span data-href="#tab-1">
                  <i class="bi bi-sign-stop-fill me-1"></i>Vehicle Stops
                </span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "virtualOdometer" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("virtualOdometer");
                }}
              >
                <span data-href="#tab-1">
                  <i className="bi bi-speedometer"></i> Virtual Odometer
                </span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "alarms" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <AlarmsReports setActiveTab={setActiveTab} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "cost_fuel" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <CostFuelReport setActiveTab={setActiveTab} />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "daily_summary" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DailySummaryReports setActiveTab={setActiveTab} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "detailed_cost" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DetailedCostReport setActiveTab={setActiveTab} />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "driver_diary" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DriverDiary setActiveTab={setActiveTab} />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "driver_event" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DrivingBehaviorEvent setActiveTab={setActiveTab} />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "fuel_managemnet" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <FuelManagement setActiveTab={setActiveTab} />
            </div>

            {/* <div
              className={`tab-pane fade ${
                activeTab === "Permanence" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <PermenenceReports setActiveTab={setActiveTab} />
            </div> */}
            <div
              className={`tab-pane fade ${
                activeTab === "roundtrips" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <RoundTripsReports setActiveTab={setActiveTab} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "route" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <RouteExecutionReport setActiveTab={setActiveTab} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "vehicleDetails" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <VehicleDetailsReport setActiveTab={setActiveTab} />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "vehicleTrip" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <VehicleTripsReport setActiveTab={setActiveTab} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "vehicleStop" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <VehicleStopReport setActiveTab={setActiveTab} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "virtualOdometer" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <VirtualOdometerReports setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;

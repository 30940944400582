import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  actionUpdateVehicle,
  getVehicleList,
} from "../../services/VehicleService";
import { actionCountryList } from "../../services/Common";
import Select from "react-select";
import {
  actioncustomerTripCreate,
  createCustomer,
  getPlaceList,
  getTripCustomerDetails,
  getViewCustomerList,
} from "../../services/CustomerService";
import DatePicker from "react-multi-date-picker";
import { getOrderStateList } from "../../services/OrderStateService";
import { getDriverList } from "../../services/TripService";

const tableLengthList = configDb.data.dataTableLength;
const OrderTypeList = [
  { value: 1, label: "Goods Transportation" },
  { value: 2, label: "Passengers Transportation" },
];

const UpdateOrder = ({
  setAddCustomerStatus,
  customerDetails,
  CustomerStatusData,
}) => {
  const [orderData, setOrderData] = useState({
    uuid: "",
    trip_id: "",
    customer_id: "",
    order_date: "",
    status_id: "",
    order_type: "",
    volume: "",
    cargo_plate_number: "",
    kilometers_number: "",
    weight: "",
    number_of_passengers: "",
    number_of_kilometers: "",
    product_name: "",
    quantity: "",
    unit_price: "",
    notes: "",
    order_image: null,
    driver_id: "",
    vehicle_id: "",
  });

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [customerViewList, setCustomerViewList] = useState([]);
  const [dates, setDates] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [customerTripDetails, setCustomerTripDetails] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [DriverList, setDriverList] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [orderImage, setorderImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setorderImage(file);
    }
  };

  const handleChangeDate = (date) => {
    setDates(date);
  };

  useEffect(() => {
    if (customerTripDetails) {
      setOrderData({
        uuid: customerTripDetails?.uuid,
        trip_id: customerTripDetails?.trip_id,
        customer_id: customerTripDetails?.customer_id,
        order_date: customerTripDetails?.order_date,
        status_id: customerTripDetails?.status_id,
        order_type: customerTripDetails?.order_type,
        volume: customerTripDetails?.volume,
        cargo_plate_number: customerTripDetails?.cargo_plate_number,
        kilometers_number: customerTripDetails?.kilometers_number,
        weight: customerTripDetails?.weight,
        number_of_passengers: customerTripDetails?.number_of_passengers,
        number_of_kilometers: customerTripDetails?.number_of_kilometers,
        product_name: customerTripDetails?.product_name,
        quantity: customerTripDetails?.quantity || 0,
        unit_price: customerTripDetails?.unit_price || 0,
        notes: customerTripDetails?.notes,
        order_image: customerTripDetails?.order_image,
        driver_id: customerTripDetails?.driver_id,
        vehicle_id: customerTripDetails?.vehicle_id,
      });
      setDates(customerTripDetails?.order_date);
      setorderImage(customerTripDetails?.order_image);
      setImagePreview(customerTripDetails?.order_image);
    }
  }, [customerTripDetails]);

  useEffect(() => {
    if (CustomerStatusData?.uuid) {
      const fetchCustomerTripDetails = async () => {
        try {
          const data = { uuid: CustomerStatusData.uuid };
          const response = await getTripCustomerDetails(data);
          setCustomerTripDetails(response.data.data);
        } catch (err) {
          toast.error(Utils.getErrorMessage(err));
        }
      };

      fetchCustomerTripDetails();
    }
  }, [CustomerStatusData]);

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      let data = params;
      getOrderStateList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setStatusList(list);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(function () {
    let data = { ...params };

    getViewCustomerList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCustomerViewList(list);
      })
      .catch((err) => {});
  }, []);

  const handleCustomerForm = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("trip_id", CustomerStatusData?.trip_id);
    formData.append("customer_id", orderData?.customer_id);
    formData.append("uuid", CustomerStatusData?.uuid);
    formData.append("order_date", orderData?.order_date);
    formData.append("status_id", orderData?.status_id);
    formData.append("order_type", orderData?.order_type);

    if (orderData?.order_type === 1) {
      formData.append("cargo_plate_number", orderData?.cargo_plate_number);
      formData.append("kilometers_number", orderData?.kilometers_number);
      formData.append("weight", orderData?.weight);
    } else {
      formData.append("number_of_passengers", orderData?.number_of_passengers);
      formData.append("number_of_kilometers", orderData?.number_of_kilometers);
    }

    formData.append("product_name", orderData?.product_name);
    formData.append("quantity", orderData?.quantity);
    formData.append("notes", orderData?.notes);
    formData.append("order_image", orderImage);
    formData.append("driver_id", orderData?.driver_id);
    formData.append("vehicle_id", orderData?.vehicle_id);
    formData.append("unit_price", orderData?.unit_price);

    modifyCustomer(actioncustomerTripCreate, formData);
  };

  const modifyCustomer = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setAddCustomerStatus(false);
        setOrderData({
          uuid: "",
          trip_id: "",
          customer_id: "",
          order_date: "",
          status_id: "",
          order_type: "",
          volume: "",
          cargo_plate_number: "",
          kilometers_number: "",
          weight: "",
          number_of_passengers: "",
          number_of_kilometers: "",
          product_name: "",
          quantity: "",
          unit_price: "",
          notes: "",
          order_image: null,
          driver_id: "",
          vehicle_id: "",
        });
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => setAddCustomerStatus(false)}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="tight-block"></div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleCustomerForm}>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Title">
                        <span>
                          <b>Order Number:</b>
                        </span>
                        {CustomerStatusData?.reference_number}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Select Customer"}
                      </Label>
                      <Select
                        options={customerViewList}
                        value={customerViewList?.find(
                          (option) => option.value === orderData.customer_id
                        )}
                        onChange={(selectedOption) =>
                          setOrderData((prevData) => ({
                            ...prevData,
                            customer_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Customer"}
                      />
                    </div>
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Order Date"}</Label>
                      <div className="input-div">
                        <DatePicker
                          selected={dates}
                          value={dates}
                          onChange={handleChangeDate}
                          dateFormat="YYYY-MM-DD"
                          calendarPosition="bottom-center"
                          placeholder="Select Date"
                          style={{
                            border: "1px solid #CCCCCC",
                            borderRadius: "5px",
                            height: "40px",
                            margin: "1px 0",
                            padding: "2px 5px",
                            width: "100%",
                            paddingRight: "30px",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Order Status"}</Label>
                      <Select
                        options={statusList}
                        value={statusList?.find(
                          (option) =>
                            option.value === Number(orderData.status_id)
                        )}
                        onChange={(selectedOption) =>
                          setOrderData((prevData) => ({
                            ...prevData,
                            status_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Order Status"}
                      />
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Order Type"}</Label>
                      <Select
                        options={OrderTypeList}
                        value={OrderTypeList?.find(
                          (option) => option.value === orderData.order_type
                        )}
                        onChange={(selectedOption) =>
                          setOrderData((prevData) => ({
                            ...prevData,
                            order_type: selectedOption.value,
                          }))
                        }
                        placeholder={"Order Type"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>
              {orderData?.order_type === 1 && (
                <>
                  {" "}
                  <h5>Service Details</h5>
                  <FormGroup>
                    <div className="add-vehicle-rw">
                      <div className="row">
                        <div className="col-md-4">
                          <Label className="col-form-label">
                            {"Volume (m³)"}
                          </Label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              id="volume"
                              type="number"
                              placeholder="Volume (m³)"
                              name={`volume`}
                              onChange={(e) => {
                                setOrderData({
                                  ...orderData,
                                  volume: e.target.value,
                                });
                              }}
                              value={orderData.volume}
                            ></input>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <Label className="col-form-label">
                            {"Number of Cargo Pallets"}
                          </Label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              id=""
                              type="number"
                              placeholder="Number of Cargo Pallets"
                              name={`cargo_plate_number`}
                              onChange={(e) => {
                                setOrderData({
                                  ...orderData,
                                  cargo_plate_number: e.target.value,
                                });
                              }}
                              value={orderData.cargo_plate_number}
                            ></input>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <Label className="col-form-label">
                            {"Number of Kilometers"}
                          </Label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              id=""
                              type="number"
                              placeholder="Number of Kilometers"
                              name={`kilometers_number`}
                              onChange={(e) => {
                                setOrderData({
                                  ...orderData,
                                  kilometers_number: e.target.value,
                                });
                              }}
                              value={orderData.kilometers_number}
                            ></input>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <Label className="col-form-label">
                            {"Weight (Tons)"}
                          </Label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              id=""
                              type="number"
                              placeholder="Weight (Tons)"
                              name={`weight`}
                              onChange={(e) => {
                                setOrderData({
                                  ...orderData,
                                  weight: e.target.value,
                                });
                              }}
                              value={orderData.weight}
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                </>
              )}

              {orderData?.order_type === 2 && (
                <>
                  {" "}
                  <h5>Service Details</h5>
                  <FormGroup>
                    <div className="add-vehicle-rw">
                      <div className="row">
                        <div className="col-md-4">
                          <Label className="col-form-label">
                            {"Number of Passengers"}
                          </Label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              id=""
                              type="number"
                              placeholder="Number of Passengers"
                              name={`number_of_passengers`}
                              onChange={(e) => {
                                setOrderData({
                                  ...orderData,
                                  number_of_passengers: e.target.value,
                                });
                              }}
                              value={orderData.number_of_passengers}
                            ></input>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <Label className="col-form-label">
                            {"Number of Kilometers"}
                          </Label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              id=""
                              type="number"
                              placeholder="Number of Kilometers"
                              name={`number_of_kilometers`}
                              onChange={(e) => {
                                setOrderData({
                                  ...orderData,
                                  number_of_kilometers: e.target.value,
                                });
                              }}
                              value={orderData.number_of_kilometers}
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                </>
              )}

              <h5>Delivery/Route Information</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Vehicle"}</Label>

                      <Select
                        options={vehicleList}
                        value={vehicleList?.find(
                          (option) => option.value === orderData.vehicle_id
                        )}
                        onChange={(selectedOption) =>
                          setOrderData((prevData) => ({
                            ...prevData,
                            vehicle_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Vehicle"}
                      />
                    </div>
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Driver"}</Label>
                      <Select
                        options={DriverList}
                        value={DriverList.find(
                          (option) => option.value === orderData.driver_id
                        )}
                        onChange={(selectedOption) =>
                          setOrderData((prevData) => ({
                            ...prevData,
                            driver_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Driver"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Product Details</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Product Name"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          type="text"
                          placeholder="Product Name"
                          name={`product_name`}
                          onChange={(e) => {
                            setOrderData({
                              ...orderData,
                              product_name: e.target.value,
                            });
                          }}
                          value={orderData.product_name}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Quantity"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          type="number"
                          placeholder="Quantity"
                          name={`quantity`}
                          onChange={(e) => {
                            setOrderData({
                              ...orderData,
                              quantity: e.target.value,
                            });
                          }}
                          value={orderData.quantity}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Unit Price"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          type="number"
                          placeholder="Unit Price"
                          name={`unit_price`}
                          onChange={(e) => {
                            setOrderData({
                              ...orderData,
                              unit_price: e.target.value,
                            });
                          }}
                          value={orderData.unit_price}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="Title">
                        <span>
                          <b>Total Of Unit price & quantity : </b>
                        </span>
                        {`${orderData.unit_price * orderData.quantity}`}
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Comments/Notes</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Comments"}</Label>
                      <div className="input-div">
                        <textarea
                          className="form-control"
                          id=""
                          type="text"
                          placeholder="Comments"
                          name={`notes`}
                          onChange={(e) => {
                            setOrderData({
                              ...orderData,
                              notes: e.target.value,
                            });
                          }}
                          value={orderData.notes}
                          rows={3}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Route Optimization Info</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-xl-12">
                        <div>
                          <i
                            className="bi bi-flag-fill me-2"
                            style={{ color: "#7ED328" }}
                          ></i>
                          Start
                          {/* <span
                            style={{ marginLeft: "7px", fontWeight: "500" }}
                          >
                            Start
                          </span> */}
                          <span style={{ color: "gray", marginLeft: "35px" }}>
                            {customerTripDetails.source_place}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div>
                          <i
                            className="bi bi-flag-fill me-2"
                            style={{ color: "red" }}
                          ></i>
                          End
                          {/* <span style={{ marginLeft: "7px", fontWeight: "500" }}>
                          End
                        </span> */}
                          <span style={{ color: "gray", marginLeft: "35px" }}>
                            {customerTripDetails?.destination_place || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Attachments</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Upload File"}</Label>
                      <div className="input-div">
                        <input
                          type="file"
                          accept="image/*" // Accept only image files
                          onChange={handleFileChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      {imagePreview && (
                        <div className="image-preview">
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ width: "100%", marginTop: "10px" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateOrder;

import axiosInstance from "./AxiosInstance";

export function createTripInvoice(data) {
  return axiosInstance.post(`/trip/trip-invoice-create`, data);
}

export function getTripInvoiceList(data) {
  return axiosInstance.post(`/trip/invoice-list`, data);
}

export function deleteTripInvoice(data) {
  return axiosInstance.post(`/trip/trip-invoice-delete`, data);
}

export function getTripInvoiceDetails(data) {
  return axiosInstance.post(`/trip/trip-invoice-details`, data);
}
import axiosInstance from "./AxiosInstance";

export function actionAlarmReports(data) {
  return axiosInstance.post(`/alarm-notification-report-list`, data);
}

export function actionFuelCostReports(data) {
  return axiosInstance.post(`/fuel-report-list`, data);
}

export function actionDailyReports(data) {
  return axiosInstance.post(`/daily-report-list`, data);
}

export function actionDetailsCost(data) {
  return axiosInstance.post(`/detailscost-report-list`, data);
}

export function getPlaceReportList(data) {
  return axiosInstance.post(`/place-report-view-list`, data);
}

export function getFuelManagementReportList(data) {
  return axiosInstance.post(`/fuel-management-report-list`, data);
}
export function getRoundTripReportList(data) {
  return axiosInstance.post(`/round-trip-report-list`, data);
}
export function getRouteReportList(data) {
  return axiosInstance.post(`/route-execution-report-list`, data);
}
export function getVitualOdometerReportList(data) {
  return axiosInstance.post(`/virtual-odometer-report-list`, data);
}
export function getVehicleDetailsReportList(data) {
  return axiosInstance.post(`/vehicle-details-report-list`, data);
}
export function getVehicleTripsReportList(data) {
  return axiosInstance.post(`/vehicle-trips-report-list`, data);
}

export function getVehicleStopsReportList(data) {
  return axiosInstance.post(`/vehicle-stops-report-list`, data);
}


import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import Select from "react-select";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createAddUserGeneral } from "../../services/EmployeeService";
import validator from "validator";
import {
  actionLanguageList,
  actionTimeAllZoneList,
} from "../../services/Common";
import {
  getDepartmentAllList,
  getSegmentAllList,
} from "../../services/ManagementService";
import { actionChangePassword } from "../../services/ProfileService";

const GeneralEmployee = ({ setActiveTab, usersDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [employeeData, setEmployeeData] = useState({
    name: "",
    short_name: "",
    language_id: "",
    timezone_id: "",
    department_id: "",
    segement_id: "",
    email: "",
  });


  const [languageList, setLanguageList] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [segementList, setSegementList] = useState([]);
  const [passwordModel, setPasswordModel] = useState(false);

  const errorsObj = { newPassword: "", confirmPassword: "", oldPassword: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isPasswordButtonLoad, setIsPasswordButtonLoad] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handlePasswordModel = () => {
    setPasswordModel(true);
  };

  const handlePasswordModelClose = () => {
    setPasswordModel(false);
  };

  const onChangePasswordSubmit = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;

    if (!newPassword) {
      errorObj.newPassword = "New Password is required";
      error = true;
    } else if (
      !validator.isStrongPassword(newPassword, ConfigDB.data.strongPassword)
    ) {
      errorObj.newPassword =
        "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
      error = true;
    }

    if (!oldPassword) {
      errorObj.oldPassword = "Old Password is required";
      error = true;
    } else if (
      !validator.isStrongPassword(oldPassword, ConfigDB.data.strongPassword)
    ) {
      errorObj.oldPassword =
        "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
      error = true;
    }

    if (oldPassword === newPassword) {
      errorObj.newPassword = "New Password should not be same as Old Password";
      error = true;
    }

    if (!confirmPassword) {
      errorObj.confirmPassword = "Confirm password is required";
      error = true;
    } else if (newPassword !== confirmPassword) {
      errorObj.confirmPassword =
        "New password and confirm password does not match";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    setIsPasswordButtonLoad(true);
    let data = { password: newPassword, oldPassword: oldPassword };
    actionChangePassword(data)
      .then((response) => {
        toast.success(response.data.message);
        setIsPasswordButtonLoad(false);
        setNewPassword("");
        setOldPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
        setIsPasswordButtonLoad(false);
      });
  };

  useEffect(function () {
    actionLanguageList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].language_name,
          });
        }
        setLanguageList(list);
      })
      .catch((err) => {});

    actionTimeAllZoneList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setTimeZoneList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getDepartmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDepartmentList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getSegmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setSegementList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (usersDetails) {
      setEmployeeData({
        name: usersDetails?.name,
        short_name: usersDetails?.short_name,
        language_id: usersDetails?.language_id,
        timezone_id: usersDetails?.timezone_id,
        department_id: usersDetails?.department_id,
        segement_id: usersDetails?.segement_id,
        email: usersDetails?.email,
      });
    }
  }, [usersDetails]);

  const handleEmployeeForm = (e) => {
    e.preventDefault();

    let data = {
      name: employeeData?.name,
      email: employeeData?.email,
      userRole: 3,
      short_name: employeeData?.short_name,
      language_id: employeeData?.language_id,
      timezone_id: employeeData?.timezone_id,
      department_id: employeeData?.department_id,
      segement_id: employeeData?.segement_id,
    };

    let updateDatadata = {
      uuid: usersDetails?.uuid,
      name: employeeData?.name,
      email: employeeData?.email,
      userRole: 3,
      short_name: employeeData?.short_name,
      language_id: employeeData?.language_id,
      timezone_id: employeeData?.timezone_id,
      department_id: employeeData?.department_id,
      segement_id: employeeData?.segement_id,
    };

    if (usersDetails?.uuid) {
      modifyEmployee(createAddUserGeneral, updateDatadata);
    } else {
      modifyEmployee(createAddUserGeneral, data);
    }
  };

  const modifyEmployee = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("filtering");
        localStorage.setItem("UserUUID", response.data.data.uuid);
        localStorage.setItem("UserId", response.data.data.id);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleEmployeeForm}>
              <h4>General</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Username"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.name}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            name: e.target.value,
                          });
                        }}
                        name={`name`}
                        placeholder="Username"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Short name"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.short_name}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            short_name: e.target.value,
                          });
                        }}
                        name={`short_name`}
                        placeholder="Short name"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Email"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.email}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            email: e.target.value,
                          });
                        }}
                        name={`email`}
                        placeholder="Email"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Password"}</Label>
                    <div className="input-div">
                      <Button onClick={handlePasswordModel}>
                        Change Password
                      </Button>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Language"}</Label>
                    <div className="input-div">
                      <Select
                        options={languageList}
                        value={languageList?.find(
                          (option) => option.value === employeeData.language_id
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            language_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Language"}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Time zone"}</Label>
                    <div className="input-div">
                      <Select
                        options={timeZoneList}
                        value={timeZoneList?.find(
                          (option) => option.value === employeeData.timezone_id
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            timezone_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Time zone"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h4>Organization</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">Department</Label>
                    <div className="input-div">
                      <Select
                        options={departmentList}
                        value={departmentList?.find(
                          (option) =>
                            option.value ===
                            JSON.stringify(employeeData.department_id)
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            department_id: selectedOption.value,
                          }))
                        }
                        placeholder={"None"}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">Segement</Label>
                    <div className="input-div">
                      <Select
                        options={segementList}
                        value={segementList?.find(
                          (option) => option.value === employeeData.segement_id
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            segement_id: selectedOption.value,
                          }))
                        }
                        placeholder={"None"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>

        <Modal isOpen={passwordModel}>
          <form method={`post`} onSubmit={onChangePasswordSubmit}>
            <ModalHeader>{`Change Password`}</ModalHeader>
            <ModalBody>
              <div className="inputDiv">
                <label className="inputLabel">Old Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                {errors.oldPassword && (
                  <span className="input-error">{errors.oldPassword}</span>
                )}
              </div>
              <div className="inputDiv">
                <label className="inputLabel">New Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {errors.newPassword && (
                  <span className="input-error">{errors.newPassword}</span>
                )}
              </div>
              <div className="inputDiv">
                <label className="inputLabel">Confirm Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errors.confirmPassword && (
                  <span className="input-error">{errors.confirmPassword}</span>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="primary"
                type={`button`}
                disabled={isPasswordButtonLoad}
                outline
                onClick={handlePasswordModelClose}
                className={`btn-square`}
              >{`Close`}</Button>
              {!isPasswordButtonLoad && (
                <Button
                  color="secondary"
                  type="submit"
                  className={`btn-square`}
                >{`Change Password`}</Button>
              )}
              {isPasswordButtonLoad && (
                <Button
                  color="secondary"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                </Button>
              )}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default GeneralEmployee;

import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { actionUpdateCapacityEquipmentVehicle } from "../../services/VehicleService";
import { useNavigate } from "react-router-dom";
import validator from "validator";

const tableLengthList = configDb.data.dataTableLength;

const Extra = ({ setActiveTab, vehicleDetails }) => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const navigate = useNavigate();
  const [modifyModal, setModifyModal] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    width: "",
    height: "",
    length: "",
    pallets: "",
    payload: "",
    gross_vehicle_weight_rating: "",
    kerb_weight: "",
    double_desk: "",
    pallet_carrier: "",
    meat_rail: "",
    securing_straps: "",
    other_equipment: "",
    refrigerated: 0,
    ref_from_temp: 0,
    ref_to_temp: 0,
    bi_temperature: 0,
    bi_from_temp: 0,
    bi_to_temp: 0,
  });
  const VehicleIDData = localStorage.getItem("vehicleId");
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [isRefrigerated, setIsRefrigerated] = useState(
    vehicleData.refrigerated === 1
  );

  const [isTemp, setIsTemp] = useState(vehicleData.bi_temperature === 1);
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? 1 : 0;
    setIsRefrigerated(isChecked);
    setVehicleData((prevData) => ({
      ...prevData,
      refrigerated: value,
      ref_from_temp: isChecked ? prevData.ref_from_temp : "",
      ref_to_temp: isChecked ? prevData.ref_to_temp : "",
    }));
  };

  const handleCheckboxChangetemperature = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? 1 : 0;
    setIsTemp(isChecked);
    setVehicleData((prevData) => ({
      ...prevData,
      bi_temperature: value,
      bi_from_temp: isChecked ? prevData.bi_from_temp : "",
      bi_to_temp: isChecked ? prevData.bi_to_temp : "",
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (vehicleDetails) {
      setVehicleData({
        width: vehicleDetails?.capacity?.width,
        height: vehicleDetails?.capacity?.height,
        length: vehicleDetails?.capacity?.length,
        pallets: vehicleDetails?.capacity?.pallets,
        payload: vehicleDetails?.capacity?.payload,
        gross_vehicle_weight_rating:
          vehicleDetails?.capacity?.gross_vehicle_weight_rating,
        kerb_weight: vehicleDetails?.capacity?.kerb_weight,
        double_desk: vehicleDetails?.capacity?.double_desk,
        pallet_carrier: vehicleDetails?.equipment?.pallet_carrier,
        meat_rail: vehicleDetails?.equipment?.meat_rail,
        securing_straps: vehicleDetails?.equipment?.securing_straps,
        other_equipment: vehicleDetails?.equipment?.other_equipment,
        refrigerated: vehicleDetails?.equipment?.refrigerated,
        ref_from_temp: vehicleDetails?.equipment?.ref_from_temp,
        ref_to_temp: vehicleDetails?.equipment?.ref_to_temp,
        bi_temperature: vehicleDetails?.equipment?.bi_temperature,
        bi_from_temp: vehicleDetails?.equipment?.bi_from_temp,
        bi_to_temp: vehicleDetails?.equipment?.bi_to_temp,
      });
    }
  }, [vehicleDetails]);

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let baseData = {
      width: vehicleData?.width,
      height: vehicleData?.height,
      length: vehicleData?.length,
      pallets: vehicleData?.pallets,
      payload: vehicleData?.payload,
      gross_vehicle_weight_rating: vehicleData?.gross_vehicle_weight_rating,
      kerb_weight: vehicleData?.kerb_weight,
      double_desk: vehicleData?.double_desk,
      pallet_carrier: vehicleData?.pallet_carrier,
      meat_rail: vehicleData?.meat_rail,
      securing_straps: vehicleData?.securing_straps,
      other_equipment: vehicleData?.other_equipment,
      refrigerated: vehicleData?.refrigerated || 0,
      ref_from_temp: vehicleData?.ref_from_temp || 0,
      ref_to_temp: vehicleData?.ref_to_temp || 0,
      bi_temperature: vehicleData?.bi_temperature || 0,
      bi_from_temp: vehicleData?.bi_from_temp || 0,
      bi_to_temp: vehicleData?.bi_to_temp || 0,
    };

    if (vehicleDetails?.equipment?.eqp_uuid || vehicleDetails?.capacity?.uuid) {
      baseData.vehicle_id = vehicleDetails?.vehicle_id;
      baseData.eqp_uuid = vehicleDetails?.equipment?.eqp_uuid;
      baseData.uuid = vehicleDetails?.capacity?.uuid;
    } else {
      baseData.vehicle_id = VehicleIDData;
    }

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      baseData.companyId = vehicleData.companyId;
    }

    modifyExtra(actionUpdateCapacityEquipmentVehicle, baseData);
  };

  const modifyExtra = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("privacyMode");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <h5>Capacity</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Width"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.width}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              width: e.target.value,
                            });
                          }}
                          name={`width`}
                          maxLength={10}
                          placeholder="Width"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Height"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.height}
                          placeholder="Height"
                          name={`height`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              height: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Length"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.length}
                          placeholder="Length"
                          name={`length`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              length: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Pallets"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.pallets}
                          placeholder="Pallets"
                          name={`pallets`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              pallets: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Payload"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.payload}
                          placeholder="Payload"
                          name={`payload`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              payload: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Gross vehicle weight rating"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.gross_vehicle_weight_rating}
                          placeholder="Gross vehicle weight rating"
                          name={`gross_vehicle_weight_rating`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              gross_vehicle_weight_rating: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Kerb Weight"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.kerb_weight}
                          placeholder="Kerb Weight"
                          name={`kerb_weight`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              kerb_weight: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Double Desk"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.double_desk}
                          placeholder="Double Desk"
                          name={`double_desk`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              double_desk: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Equipment</h5>

              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Pallet Carrier"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.pallet_carrier}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              pallet_carrier: e.target.value,
                            });
                          }}
                          name={`pallet_carrier`}
                          maxLength={10}
                          placeholder="Pallet Carrier"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Meat rail"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.meat_rail}
                          placeholder="Height"
                          name={`meat_rail`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              meat_rail: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Securing Straps"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.securing_straps}
                          placeholder="Securing Straps"
                          name={`securing_straps`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              securing_straps: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Other Equipment"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.other_equipment}
                          placeholder="Other Equipment"
                          name={`other_equipment`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              other_equipment: e.target.value,
                            });
                          }}
                          maxLength={10}
                        ></input>
                      </div>
                    </div>

                    <div
                      className="col-md-4 refrigerated"
                    >
                      <label className="col-form-label">
                        <input
                          type="checkbox"
                          checked={isRefrigerated}
                          onChange={handleCheckboxChange}
                        />
                        {" Refrigerated"}
                      </label>
                    </div>

                    <div
                      className="col-md-4 refrigerated"
                    >
                      <label className="col-form-label ">
                        <input
                          type="checkbox"
                          checked={isTemp}
                          onChange={handleCheckboxChangetemperature}
                        />
                        {" Bi-temperature"}
                      </label>
                    </div>

                    {vehicleData.bi_temperature === 1 && (
                      <>
                        <div className="col-md-4">
                          <label className="col-form-label">
                            {"Bi-Temp from(0,0 °C)"}
                          </label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              name="bi_from_temp"
                              value={vehicleData.bi_from_temp}
                              placeholder="Bi from(0,0 °C)"
                              onChange={handleInputChange}
                              maxLength={10}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label">
                            {"Bi-Temp to(0,0 °C)"}
                          </label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              name="bi_to_temp"
                              value={vehicleData.bi_to_temp}
                              placeholder="Bi-Temp to(0,0 °C)"
                              onChange={handleInputChange}
                              maxLength={10}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {vehicleData.refrigerated === 1 && (
                      <>
                        <div className="col-md-4">
                          <label className="col-form-label">
                            {"Refrigerated from(0,0 °C)"}
                          </label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              name="ref_from_temp"
                              value={vehicleData.ref_from_temp}
                              placeholder="Refrigerated from(0,0 °C)"
                              onChange={handleInputChange}
                              maxLength={10}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label">
                            {"Refrigerated to(0,0 °C)"}
                          </label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              name="ref_to_temp"
                              value={vehicleData.ref_to_temp}
                              placeholder="Refrigerated to(0,0 °C)"
                              onChange={handleInputChange}
                              maxLength={10}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Extra;

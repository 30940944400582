import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  actionUpdateVehicle,
  getVehicleList,
} from "../../services/VehicleService";
import { actionCountryList } from "../../services/Common";
import Select from "react-select";
import {
  actioncustomerTripCreate,
  createCustomer,
  getPlaceList,
  getTripCustomerDetails,
  getViewCustomerList,
} from "../../services/CustomerService";
import DatePicker from "react-multi-date-picker";
import { getOrderStateList } from "../../services/OrderStateService";
import { getDriverList } from "../../services/TripService";
import {
  createTripInvoice,
  getTripInvoiceDetails,
  getTripInvoiceList,
} from "../../services/TripInvoice";

const tableLengthList = configDb.data.dataTableLength;
const OrderTypeList = [
  { value: 1, label: "Unpaid" },
  { value: 2, label: "Paid" },
  { value: 3, label: "Overdue" },
];

const VATList = [
  { value: 1, label: "0%" },
  { value: 2, label: "6%" },
  { value: 3, label: "13%" },
  { value: 4, label: "23%" },
];

const UpdateInvoice = ({
  setAddInvoiceStatus,
  customerDetails,
  InvoiceAddData,
}) => {
  console.log("InvoiceAddData", InvoiceAddData);
  const [invoiceData, setInvoiceData] = useState({
    uuid: "",
    trip_id: "",
    trip_details_id: "",
    invoice_image: "",
    customer_id: "",
    invoice_date: "",
    due_date: "",
    payment_date: "",
    status_id: "",
    notes: "",
    invoice_number: "",
  });

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [customerViewList, setCustomerViewList] = useState([]);
  const [dates, setDates] = useState("");
  const [DueDates, setDueDates] = useState("");
  const [PaymentDates, setPaymentDates] = useState("");

  const [statusList, setStatusList] = useState([]);
  const [customerTripDetails, setCustomerTripDetails] = useState([]);
  const [InvoiceDetails, setInvoiceDetails] = useState("");

  const [vehicleList, setVehicleList] = useState([]);
  const [DriverList, setDriverList] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [orderImage, setorderImage] = useState(null);
  const [products, setProducts] = useState([]);
  const addProductRow = () => {
    setProducts((prevProducts) => {
      if (!Array.isArray(prevProducts)) {
        return [];
      }
      return [
        ...prevProducts,
        {
          product_name: "",
          quantity: "",
          unit_price: "",
          discount: "",
          vat: "",
          isOpen: true,
        },
      ];
    });
  };

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      if (InvoiceAddData?.uuid) {
        try {
          const data = { uuid: InvoiceAddData.uuid };
          const response = await getTripInvoiceDetails(data);
          setInvoiceDetails(response.data.data);
        } catch (err) {
          toast.error(Utils.getErrorMessage(err));
        }
      }
    };

    fetchInvoiceDetails();
  }, [InvoiceAddData]);

  const removeProductRow = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const handleInputChange = (index, field, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][field] = value;

    const quantity = parseFloat(updatedProducts[index].quantity) || 0;
    const unitPrice = parseFloat(updatedProducts[index].unit_price) || 0;
    const discount = parseFloat(updatedProducts[index].discount) || 0;
    const vat = parseFloat(updatedProducts[index].vat) || 0;

    updatedProducts[index].total = quantity * unitPrice - discount + vat;

    setProducts(updatedProducts);
  };

  useEffect(() => {
    if (InvoiceDetails) {
      setInvoiceData({
        uuid: InvoiceDetails?.uuid,
        trip_id: InvoiceDetails?.trip_id,
        trip_details_id: InvoiceDetails?.trip_details_id,
        invoice_image: InvoiceDetails?.invoice_image,
        customer_id: InvoiceDetails?.customer_id,
        invoice_date: InvoiceDetails?.invoice_date,
        due_date: InvoiceDetails?.due_date,
        payment_date: InvoiceDetails?.payment_date,
        status_id: InvoiceDetails?.status_id,
        notes: InvoiceDetails?.notes,
        invoice_number: InvoiceDetails?.invoice_number,
      });
      setorderImage(InvoiceDetails?.invoice_image);
      setImagePreview(InvoiceDetails?.invoice_image);
      setDates(InvoiceDetails?.invoice_date);
      setDueDates(InvoiceDetails?.due_date);
      setPaymentDates(InvoiceDetails?.payment_date);
      setProducts(InvoiceDetails?.invoice_details);
    }
  }, [InvoiceDetails]);

  const calculateSubtotal = () => {
    return products?.reduce((acc, product) => {
      const quantity = parseFloat(product.quantity) || 0;
      const unitPrice = parseFloat(product.unit_price) || 0;
      return acc + quantity * unitPrice;
    }, 0);
  };

  const calculateVAT = () => {
    return products?.reduce((acc, product) => {
      const vat = parseFloat(product.vat) || 0;
      return acc + (calculateSubtotal() * vat) / 100;
    }, 0);
  };

  const calculateAmountTotal = () => {
    return products?.reduce((acc, product) => {
      const quantity = parseFloat(product.quantity) || 0;
      const unitPrice = parseFloat(product.unit_price) || 0;
      const discount = parseFloat(product.discount) || 0;
      const vat = parseFloat(product.vat) || 0;
      return acc + quantity * unitPrice - discount + vat;
    }, 0);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setorderImage(file);
    }
  };

  const handleChangeDate = (date) => {
    setDates(date);
  };

  const handleChangeDueDate = (date) => {
    setDueDates(date);
  };

  const handleChangePaymentDate = (date) => {
    setPaymentDates(date);
  };
  useEffect(() => {
    if (InvoiceAddData?.uuid) {
      const fetchCustomerTripDetails = async () => {
        try {
          const data = { uuid: InvoiceAddData?.uuid };
          const response = await getTripCustomerDetails(data);
          setCustomerTripDetails(response.data.data);
        } catch (err) {
          toast.error(Utils.getErrorMessage(err));
        }
      };

      fetchCustomerTripDetails();
    }
  }, [InvoiceAddData]);

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => { });
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => { });
    },
    [params]
  );

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => { });
  }, []);

  useEffect(
    function () {
      let data = params;
      getOrderStateList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setStatusList(list);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(function () {
    let data = { ...params };

    getViewCustomerList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCustomerViewList(list);
      })
      .catch((err) => { });
  }, []);

  const handleCustomerForm = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("trip_id", customerTripDetails?.trip_id);
    formData.append("customer_id", invoiceData?.customer_id);
    formData.append("trip_details_id", customerTripDetails?.id);
    formData.append("invoice_date", dates);
    formData.append("due_date", DueDates);
    formData.append("payment_date", PaymentDates);
    formData.append("notes", invoiceData?.notes);
    formData.append("status_id", invoiceData?.status_id);
    formData.append("invoice_image", orderImage);
    formData.append("invoice_details", JSON.stringify(products));

    const formDatas = new FormData();
    formDatas.append("trip_id", InvoiceDetails?.trip_id);
    formDatas.append("uuid", invoiceData?.uuid);
    formDatas.append("customer_id", invoiceData?.customer_id);
    formDatas.append("trip_details_id", InvoiceDetails?.id);
    formDatas.append("invoice_date", dates);
    formDatas.append("due_date", DueDates);
    formDatas.append("payment_date", PaymentDates);
    formDatas.append("notes", invoiceData?.notes);
    formDatas.append("status_id", invoiceData?.status_id);
    formDatas.append("invoice_image", orderImage);
    formDatas.append("invoice_details", JSON.stringify(products));
    if (invoiceData?.uuid) {
      modifyCustomer(createTripInvoice, formDatas);
    } else {
      modifyCustomer(createTripInvoice, formData);
    }

    // products.forEach((product, index) => {
    //   formData.append(
    //     `invoice_details[${index}].product_name`,
    //     product.product_name
    //   );
    //   formData.append(`invoice_details[${index}].quantity`, product.quantity);
    //   formData.append(
    //     `invoice_details[${index}].unit_price`,
    //     product.unit_price
    //   );
    //   formData.append(`invoice_details[${index}].discount`, product.discount);
    //   formData.append(`invoice_details[${index}].vat`, product.vat);
    // });
  };

  const modifyCustomer = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setAddInvoiceStatus(false);
        setInvoiceData({
          uuid: "",
          trip_id: "",
          customer_id: "",
          order_date: "",
          status_id: "",
          order_type: "",
          volume: "",
          cargo_plate_number: "",
          kilometers_number: "",
          weight: "",
          number_of_passengers: "",
          number_of_kilometers: "",
          product_name: "",
          quantity: "",
          unit_price: "",
          notes: "",
          order_image: null,
          driver_id: "",
          vehicle_id: "",
        });

        // let data = { ...params };
        // getTripInvoiceList(data)
        //   .then((response) => {
        //     // setTotalRecords(response.data.total);
        //     // setOrderList(response.data);
        //   })
        //   .catch((err) => {
        //     toast.error(Utils.getErrorMessage(err));
        //   });
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => setAddInvoiceStatus(false)}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="tight-block"></div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleCustomerForm}>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    {InvoiceDetails?.invoice_number && (
                      <div className="col-md-4">
                        <div className="Title">
                          <span>
                            <b>Invoice Number:</b>
                          </span>
                          {InvoiceDetails?.invoice_number}
                        </div>
                      </div>
                    )}

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Select Customer"}
                      </Label>
                      <Select
                        options={customerViewList}
                        value={customerViewList?.find(
                          (option) => option.value === invoiceData.customer_id
                        )}
                        onChange={(selectedOption) =>
                          setInvoiceData((prevData) => ({
                            ...prevData,
                            customer_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Customer"}
                      />
                    </div>
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Invoice Date"}</Label>
                      <div className="input-div">
                        <DatePicker
                          selected={dates}
                          value={dates}
                          onChange={handleChangeDate}
                          dateFormat="YYYY-MM-DD"
                          calendarPosition="bottom-center"
                          placeholder="Select Date"
                          style={{
                            border: "1px solid #CCCCCC",
                            borderRadius: "5px",
                            height: "40px",
                            margin: "1px 0",
                            padding: "2px 5px",
                            width: "100%",
                            paddingRight: "30px",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Due Date"}</Label>
                      <div className="input-div">
                        <DatePicker
                          selected={DueDates}
                          value={DueDates}
                          onChange={handleChangeDueDate}
                          dateFormat="YYYY-MM-DD"
                          calendarPosition="bottom-center"
                          placeholder="Select Date"
                          style={{
                            border: "1px solid #CCCCCC",
                            borderRadius: "5px",
                            height: "40px",
                            margin: "1px 0",
                            padding: "2px 5px",
                            width: "100%",
                            paddingRight: "30px",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Payment Date"}</Label>
                      <div className="input-div">
                        <DatePicker
                          selected={PaymentDates}
                          value={PaymentDates}
                          onChange={handleChangePaymentDate}
                          dateFormat="YYYY-MM-DD"
                          calendarPosition="bottom-center"
                          placeholder="Select Date"
                          style={{
                            border: "1px solid #CCCCCC",
                            borderRadius: "5px",
                            height: "40px",
                            margin: "1px 0",
                            padding: "2px 5px",
                            width: "100%",
                            paddingRight: "30px",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Payment Mode"}</Label>
                      <Select
                        options={OrderTypeList}
                        value={OrderTypeList?.find(
                          (option) => option.value === invoiceData.status_id
                        )}
                        onChange={(selectedOption) =>
                          setInvoiceData((prevData) => ({
                            ...prevData,
                            status_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Payment Mode"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Products/Services</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div>
                    {products?.map((product, index) => (
                      <div className="row" key={index}>
                        {product.isOpen && (
                          <>
                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Product Name"}
                              </Label>
                              <div className="input-div">
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Product Name"
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "product_name",
                                      e.target.value
                                    )
                                  }
                                  value={product.product_name}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Quantity"}
                              </Label>
                              <div className="input-div">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  value={product.quantity}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Unit Price"}
                              </Label>
                              <div className="input-div">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Unit Price"
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "unit_price",
                                      e.target.value
                                    )
                                  }
                                  value={product.unit_price}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Discount (%)"}
                              </Label>
                              <div className="input-div">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Discount"
                                  min="0"
                                  max="100"
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "discount",
                                      e.target.value
                                    )
                                  }
                                  value={product.discount}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <Label className="col-form-label">{"VAT"}</Label>
                              <Select
                                options={VATList}
                                value={VATList?.find(
                                  (option) => option.value === product.vat
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChange(
                                    index,
                                    "vat",
                                    selectedOption?.value
                                  )
                                }
                                placeholder={"VAT"}
                              />
                            </div>

                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Total"}
                              </Label>
                              <div className="input-div">
                                <input
                                  className="form-control"
                                  type="text"
                                  readOnly
                                  value={product?.total}
                                />
                              </div>
                            </div>

                            <div className="col-md-12 d-flex justify-content-end" style={{position: "relative", top: "31px" , left: "0", marginLeft: "-42px"}}>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => removeProductRow(index)}
                              >
                                <i class="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-md-12 justify-content-end d-flex">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={addProductRow}
                        >
                          <i class="bi bi-plus-lg"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>SubTotals</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Subtotal"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={calculateSubtotal()}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"VAT Amount"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={calculateVAT()}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Total Amount Due"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={calculateAmountTotal()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Comments/Notes</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Comments"}</Label>
                      <div className="input-div">
                        <textarea
                          className="form-control"
                          id=""
                          type="text"
                          placeholder="Comments"
                          name={`notes`}
                          onChange={(e) => {
                            setInvoiceData({
                              ...invoiceData,
                              notes: e.target.value,
                            });
                          }}
                          value={invoiceData.notes}
                          rows={3}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Attachments</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Upload File"}</Label>
                      <div className="input-div">
                        <input
                          type="file"
                          accept="image/*" // Accept only image files
                          onChange={handleFileChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      {imagePreview && (
                        <div className="image-preview">
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ width: "100%", marginTop: "10px" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateInvoice;

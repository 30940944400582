import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { NavLink, useNavigate } from "react-router-dom";
import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import {
  actionClassVehicle,
  getVehicleActivityList,
  getVehicleList,
} from "../../services/VehicleService";
import { actionCompanyList } from "../../services/Common";
import Select from "react-select";
import { getDriverList } from "../../services/TripService";
import RadioIcon from "../../assets/images/radio-red.svg";
import RadioGreenIcon from "../../assets/images/radio-green.svg";
import moment from "moment/moment";

const tableLengthList = configDb.data.dataTableLength;

const VehicleActivity = () => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const navigate = useNavigate();
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleListFilter, setVehicleListFilter] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    // departure_time:""
  });
  const [vehicle, setVehicle] = useState("");

  useEffect(function () {
    actionCompanyList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setCompanyList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    let data = params;
    getVehicleList(data)
      .then((response) => {
        response = response.data;
        let list = response.data.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.vehicleName,
        }));
        setVehicleListFilter(list);
      })
      .catch((err) => {});
  }, [params]);

  useEffect(
    function () {
      let data = {
        limit: tableLengthList[0],
        page: 1,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
      };
      setIsLoading(true);
      getVehicleActivityList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "vehicle") {
      paramsObj.vehicleId = e.value;
      paramsObj.page = 1;
    }
    setParams(paramsObj);
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Activity Today</h5>
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row company-section-Area">
          <div className="row">
            <div className="col">
              <div className="row row-cols-1 row-cols-lg-3 g-2 g-lg-3 company-section">
                <div className="col-xl-4">
                  <div className="selected-block selected-mobile">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Search</p>
                    <div className="search-bar">
                      <i className="bi bi-search"></i>
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Time</p>
                    <div className="input-group time">
                      <input
                        type="time"
                        className="form-control"
                        value={params.departure_time}
                        onChange={(e) => handleParams(e, "departure_time")}
                        placeholder="Time"
                        style={{ marginBottom: "-15px", marginLeft: "10px" }}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Vehicle</p>
                    <div style={{ width: "100%" }}>
                      <Select
                        options={vehicleListFilter}
                        value={vehicleListFilter?.find(
                          (option) => option.value === params.vehicleId
                        )}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setVehicle((prevData) => ({
                              ...prevData,
                              vehicleId: selectedOption.value,
                            }));
                            handleParams(selectedOption, "vehicle");
                          }
                        }}
                        placeholder="Select Vehicle"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-table-row page-tableactiviti">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Vehicle`}</th>
                <th scope={`col`}>{`Driver`}</th>
                <th scope="col">{`Driving Time`}</th>

                <th scope={`col`}>{`Mileage`}</th>
                <th scope={`col`}>{`Beginning of Day`}</th>
                <th scope={`col`}>{`Number of Trips`}</th>
                <th scope={`col`}>{`Departure Time`}</th>
                <th scope={`col`}>{`Departure`}</th>
                <th scope={`col`}>{`Arrival Time`}</th>
                <th scope={`col`}>{`Arrival`}</th>
                <th scope={`col`}>{`Last Trip Mileage`}</th>
              </tr>
            </thead>
            <tbody>
              {vehicleList &&
                vehicleList?.data?.map((item, i) => {
                  const dailyDrivingTime = item.time.dailyDrivingTime; 
                  const remainingDailyTime = item.time.remainingDailyTime; 

                  const dailyTime = parseFloat(dailyDrivingTime);

                  const remainingTime = parseFloat(remainingDailyTime);
                  const totalTime = dailyTime + remainingTime;

                  const percentage =
                    totalTime > 0 ? (dailyTime / totalTime) * 100 : 0;

                  let color;

                  if (dailyTime > remainingTime) {
                    color = "bg-danger";
                  } else if (dailyTime === 0) {
                    color = "bg-success";
                  } else {
                    color = "default";
                  }


                  const continuousDrivingTime = item.time.continuousDrivingTime; 
                  const remainingContinuousTime = item.time.remainingContinuousTime; 

                  const continuousTime = parseFloat(continuousDrivingTime);

                  const remainingcontinuousTime = parseFloat(remainingContinuousTime);
                  const totalcontinuousTime = continuousTime + remainingcontinuousTime;

                  const percentagecontinuous =
                    totalcontinuousTime > 0 ? (continuousTime / totalcontinuousTime) * 100 : 0;

                  let colors;

                  if (continuousTime > remainingcontinuousTime) {
                    colors = "bg-danger";
                  } else if (continuousTime === 0) {
                    colors = "bg-success";
                  } else {
                    colors = "default";
                  }


                  return (
                    <tr key={i}>
                      <td style={{ cursor: "pointer", textAlign: "left" }}>
                        <i className="icon-radio">
                          <img
                            src={
                              parseInt(item.ignition) === 1
                                ? RadioGreenIcon
                                : RadioIcon
                            }
                            alt="Radio Icon"
                          />
                        </i>{" "}
                        {item.vehicleName}
                      </td>
                      <td style={{ cursor: "pointer" }}>
                        {item.driver_name || "-"}
                      </td>
                      <td style={{ padding: "0px" }} >
                        {/* <div className="progress">
                          <div
                            className={`progress-bar progress-bar-striped bg-danger`}
                            role="progressbar"
                            aria-valuenow={percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${percentage}%` }}
                          ></div>
                        </div> */}

                        <div className="d-flex">
                          <div className="">
                            Daily
                            <div class="progress" style={{ height: "9px", width:"50px", margin:"auto" }}>
                              <div
                                className={`progress-bar progress-bar-striped ${color}`}
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={percentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            {dailyDrivingTime}
                          </div>

                          <div className="">
                          Continuous
                            <div class="progress" style={{ height: "9px",  width:"50px", margin:"auto" }}>
                              <div
                                className={`progress-bar progress-bar-striped ${colors}`}
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={percentagecontinuous}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            {dailyDrivingTime}
                          </div>
                        </div>
                      </td>

                      <td style={{ cursor: "pointer" }}>
                        {item.total_mileage || 0}KM
                      </td>
                      <td style={{ cursor: "pointer", textAlign: "left" }}>
                        {item.timestamp
                          ? moment(item.timestamp).format("lll")
                          : `${moment().format("MMMM DD, YYYY")}, 00:00`}
                      </td>
                      <td style={{ cursor: "pointer", textAlign: "left" }}>
                        {item.tripCount || 0}
                      </td>
                      <td style={{ cursor: "pointer", textAlign: "left" }}>
                        {item.departure_time
                          ? moment(item.departure_time).format("lll")
                          : "-"}
                      </td>
                      <td style={{ cursor: "pointer", textAlign: "left" }}>
                        {item.arrival_locationName || "-"}
                      </td>
                      <td style={{ cursor: "pointer", textAlign: "left" }}>
                        {item.arrival_time
                          ? moment(item.arrival_time).format("lll")
                          : "-"}
                      </td>

                      <td style={{ cursor: "pointer", textAlign: "left" }}>
                        {item.departure_locationName || "-"}
                      </td>

                      <td style={{ cursor: "pointer", textAlign: "left" }}>
                        {item.last_total_mileage || 0}KM
                      </td>
                    </tr>
                  );
                })}

              {vehicleList && vehicleList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modify_vehicle"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_vehicle"
                      ) > -1
                        ? 7
                        : 7
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>
    </>
  );
};

export default VehicleActivity;

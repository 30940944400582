import React, { useEffect, useState } from "react";
import {
  actionAddNotification,
  getAllgeoFenseList,
  getAllVehicleTaskList,
  getNotificationList,
} from "../../services/AlarmNotificationService";
import { getVehicleList } from "../../services/VehicleService";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import Select from "react-select";

const tableLengthList = configDb.data.dataTableLength;

const GeneralNotification = ({
  setNotificationStatus,
  NotificationDataAdd,
}) => {
  const Type = [
    { value: 1, label: "OverSpeed", name: "over_speed" },
    { value: 2, label: "LowerSpeed", name: "low_speed" },
    { value: 3, label: "Maintainance Task", name: "maintainance_task" },
    { value: 4, label: "Geo Fense IN", name: "in" },
    { value: 5, label: "Temperature", name: "temperature" },
    { value: 6, label: "Geo Fense OUT", name: "out" },
    // { value: 7, label: "Fuel" },
  ];
  const [notificationData, setNotificationData] = useState({
    uuid: "",
    type_name: "",
    temperature: "",
    lower_speed_limit: "",
    over_speed_limit: "",
    maintainance_task_id: "",
    email: "",
    geo_fense_id: "",
    vehicle_id: "",
  });
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleTaskList, setVehicleTaskList] = useState([]);
  const [geofenseList, setgeoFenseList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  let errorsObj = {
    email: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setNotificationData((prevData) => ({
      ...prevData,
      vehicle_id: selectedValues,
    }));
  };

  useEffect(() => {
    if (NotificationDataAdd) {
      let typeName = "";

      switch (NotificationDataAdd.type_name) {
        case "over_speed":
          typeName = 1;
          break;
        case "low_speed":
          typeName = 2;
          break;
        case "maintainance_task":
          typeName = 3;
          break;
        case "in":
          typeName = 4;
          break;
        case "temperature":
          typeName = 5;
          break;
        case "out":
          typeName = 6;
          break;
        default:
          typeName = "Unknown Type";
          break;
      }

      setNotificationData({
        uuid: NotificationDataAdd?.uuid,
        type_name: typeName || NotificationDataAdd?.type_name,
        temperature: NotificationDataAdd?.temperature,
        lower_speed_limit: NotificationDataAdd?.lower_speed_limit,
        over_speed_limit: NotificationDataAdd?.over_speed_limit,
        maintainance_task_id: NotificationDataAdd?.maintainance_task_id,
        email: NotificationDataAdd?.email,
        geo_fense_id: NotificationDataAdd?.geo_fense_id,
        vehicle_id: NotificationDataAdd.vehicle_id,
      });
    }
  }, []);

  const handleAddNotificationForm = (e) => {
    e.preventDefault();
    // let errorObj = { ...errorsObj };
    // let error = false;

    // if (!validator.isEmail(email)) {
    //   errorObj.email = "Please enter valid email address";
    //   error = true;
    // }

    // setErrors(errorObj);
    // if (error) return;

    let data = {
      type_name: notificationData.type_name,
      temperature: notificationData.temperature,
      lower_speed_limit: notificationData.lower_speed_limit,
      over_speed_limit: notificationData.over_speed_limit,
      maintainance_task_id: notificationData.maintainance_task_id,
      email: notificationData.email,
      geo_fense_id: notificationData.geo_fense_id,
      vehicle_id: notificationData.vehicle_id,
      temperature: notificationData.temperature,
    };

    let updatedata = {
      type_name: notificationData.type_name,
      temperature: notificationData.temperature,
      lower_speed_limit: notificationData.lower_speed_limit,
      over_speed_limit: notificationData.over_speed_limit,
      maintainance_task_id: notificationData.maintainance_task_id,
      email: notificationData.email,
      geo_fense_id: notificationData.geo_fense_id,
      vehicle_id: notificationData.vehicle_id,
      temperature: notificationData.temperature,
      uuid: notificationData.uuid,
    };

    if (NotificationDataAdd?.uuid) {
      modifySubscription(actionAddNotification, updatedata);
    } else {
      modifySubscription(actionAddNotification, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setNotificationStatus(false);
        setNotificationData({
          type_name: "",
          temperature: "",
          lower_speed_limit: "",
          over_speed_limit: "",
          maintainance_task_id: "",
          email: "",
          geo_fense_id: "",
          vehicle_id: "",
          temperature: "",
        });
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(() => {
    let data = params;
    getVehicleList(data)
      .then((response) => {
        response = response.data;
        let list = [];

        list.push({ value: "All", label: "All" });

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicleName,
          });
        }

        setVehicleList(list);
      })
      .catch((err) => {
        console.error("Error fetching vehicle list:", err);
      });
  }, [params]);

  useEffect(
    function () {
      let data = params;
      getAllVehicleTaskList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].task_name,
            });
          }

          setVehicleTaskList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getAllgeoFenseList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].radius_zonename,
            });
          }

          setgeoFenseList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getNotificationList(data)
        .then((response) => {
          setNotificationList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleAddNotificationForm} className="row">
              <FormGroup className="col-lg-6">
                <Label className="col-form-label">
                  {"Alert Type"}
                  <span className={`text-danger ml-1`}>*</span>
                </Label>
                <div className="mb-3">
                  <Select
                    options={Type}
                    value={Type.find(
                      (option) => option.value === notificationData.type_name
                    )}
                    onChange={(selectedOption) =>
                      setNotificationData((prevData) => ({
                        ...prevData,
                        type_name: selectedOption.name,
                      }))
                    }
                    placeholder={"Select Alert Type"}
                  />
                  {errors.type_name && (
                    <span className="input-error">{errors.type_name}</span>
                  )}
                </div>
              </FormGroup>
              {(notificationData.type_name === "over_speed" ||
                notificationData.type_name === 1) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">
                    {"OverSpeed Limit"}
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.over_speed_limit}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          over_speed_limit: e.target.value,
                        });
                      }}
                      name="over_speed_limit"
                      maxLength={50}
                      placeholder="OverSpeed Limit"
                    />
                    {errors.over_speed_limit && (
                      <span className="input-error">
                        {errors.over_speed_limit}
                      </span>
                    )}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "low_speed" ||
                notificationData.type_name === 2) && (
                <FormGroup>
                  <Label className="col-form-label">
                    {"Low Speed Limit"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.lower_speed_limit}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          lower_speed_limit: e.target.value,
                        });
                      }}
                      name={`lower_speed_limit`}
                      maxLength={50}
                      placeholder="Low Speed Limit"
                    />
                    {errors.lower_speed_limit && (
                      <span className="input-error">
                        {errors.lower_speed_limit}
                      </span>
                    )}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "maintainance_task" ||
                notificationData.type_name === 3) && (
                <FormGroup>
                  <Label className="col-form-label">
                    {"Maintainance Task"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="mb-3">
                    <Select
                      options={vehicleTaskList}
                      value={vehicleTaskList.find(
                        (option) =>
                          option.value === notificationData.maintainance_task_id
                      )}
                      onChange={(selectedOption) =>
                        setNotificationData((prevData) => ({
                          ...prevData,
                          maintainance_task_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Maintainance Task"}
                    />
                    {/* {errors.type && (
      <span className="input-error">{errors.type}</span>
    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "in" ||
                notificationData.type_name === 4) && (
                <>
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Zone"}
                      <span className={`text-danger ml-1`}>*</span>
                    </Label>
                    <div className="mb-3">
                      <Select
                        options={geofenseList}
                        value={geofenseList.find(
                          (option) =>
                            option.value === notificationData.geo_fense_id
                        )}
                        onChange={(selectedOption) =>
                          setNotificationData((prevData) => ({
                            ...prevData,
                            geo_fense_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Zone"}
                      />
                      {/* {errors.type && (
       <span className="input-error">{errors.type}</span>
     )} */}
                    </div>
                  </FormGroup>
                </>
              )}

              {(notificationData.type_name === "out" ||
                notificationData.type_name === 6) && (
                <>
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Zone"}
                      <span className={`text-danger ml-1`}>*</span>
                    </Label>
                    <div className="mb-3">
                      <Select
                        options={geofenseList}
                        value={geofenseList.find(
                          (option) =>
                            option.value === notificationData.geo_fense_id
                        )}
                        onChange={(selectedOption) =>
                          setNotificationData((prevData) => ({
                            ...prevData,
                            geo_fense_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Zone"}
                      />
                    </div>
                  </FormGroup>
                </>
              )}

              {(notificationData.type_name === "temperature" ||
                notificationData.type_name === 5) && (
                <FormGroup>
                  <Label className="col-form-label">
                    {"Temperature"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.temperature}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          temperature: e.target.value,
                        });
                      }}
                      name={`temperature`}
                      maxLength={50}
                      placeholder="Temperature"
                    />
                    {errors.temperature && (
                      <span className="input-error">{errors.temperature}</span>
                    )}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "over_speed" ||
                notificationData.type_name === "low_speed" ||
                notificationData.type_name === "maintainance_task" ||
                notificationData.type_name === "in" ||
                notificationData.type_name === "temperature" ||
                notificationData.type_name === "out" ||
                notificationData.type_name === 1 ||
                notificationData.type_name === 2 ||
                notificationData.type_name === 3 ||
                notificationData.type_name === 4 ||
                notificationData.type_name === 5 ||
                notificationData.type_name === 6) && (
                <>
                  <FormGroup className="col-lg-6">
                    <Label className="col-form-label">
                      {"Email"}
                      <span className={`text-danger ml-1`}>*</span>
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={notificationData.email}
                        onChange={(e) => {
                          setNotificationData({
                            ...notificationData,
                            email: e.target.value,
                          });
                        }}
                        name={`email`}
                        maxLength={50}
                        placeholder="Email"
                      />
                      {errors.email && (
                        <span className="input-error">{errors.email}</span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup className="col-lg-6">
                    <Label className="col-form-label">
                      {"Vehicle"}
                      <span className={`text-danger ml-1`}>*</span>
                    </Label>
                    <div className="input-div">
                      <Select
                        options={vehicleList}
                        isMulti
                        value={vehicleList.filter(
                          (option) =>
                            Array.isArray(notificationData.vehicle_id) &&
                            notificationData.vehicle_id.includes(option.value)
                        )}
                        onChange={handleChange}
                        placeholder={"Select Vehicle"}
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </FormGroup>
                </>
              )}

             
            </Form>
            <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square col-lg-1`}
              >{`Submit`}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralNotification;

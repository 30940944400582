import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import { actionDelCompany } from "../../services/CompanyService";
import {
  actionDelTrip,
  getScheduleList,
  getTripList,
  getTripTemplateList,
} from "../../services/TripService";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import plusIcon from "../../assets/images/plus-i.svg";
import EditTemplateTrip from "./EditTemplateTrip";
import moment from "moment";

const tableLengthList = configDb.data.dataTableLength;

const ScheduleRoute = (props) => {
  const allPermissionsList = useSelector((x) => x.permission.value);
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const [ScheduleList, setScheduleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [delModal, setDelModal] = useState(false);
  const [delCompany, setDelCompany] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const navigate = useNavigate();

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getScheduleList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setScheduleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onDeleteScedule = (e, item) => {
    e.preventDefault();

    setDelModal(true);
    setDelCompany(item.uuid);
  };
  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelCompany("");
    setDelModal(false);
  };

  const onDestroyCompany = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delCompany };
    actionDelTrip(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelCompany("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Schedule Route</h5>
        </div>
       
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row company-section company-section-Area">
                <div className="col-md-2">
                  <div className="row">
                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Show</p>
                        <select
                          className="form-select form-control-show"
                          aria-label="Events Only"
                          defaultValue={params.limit}
                          onChange={(e) => handleParams(e, "length")}
                        >
                          {tableLengthList &&
                            tableLengthList.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">

                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Search</p>
                    <div className="search-bar">
                      <i className="bi bi-search"></i>
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Vehicle`}</th>
                <th scope={`col`}>{`Route`}</th>
                <th scope={`col`}>{`Code`}</th>
                <th scope={`col`}>{`Week Day`}</th>
                <th scope={`col`}>{`Start Time`}</th>
                <th scope={`col`}>{`Created On`}</th>

                {allPermissionsList.findIndex(
                  (e) => e.name === "view_details_trip"
                ) > -1 && <th scope={`col`}>{`Action`}</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {ScheduleList &&
                    ScheduleList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td style={{ cursor: "pointer" }}>
                          {item.vehicle_name}
                        </td>
                        <td style={{ cursor: "pointer" }}>{item?.trip_name}</td>
                        <td style={{ cursor: "pointer" }}></td>

                        <td style={{ cursor: "pointer" }}>{item?.weekday}</td>
                        <td style={{ cursor: "pointer" }}>{item.start_time}</td>
                        <td style={{ cursor: "pointer" }}>
                          {moment(item.created_at)
                            .utc()
                            .format("DD/MM/YYYY HH:mm")}
                        </td>

                        {(allPermissionsList.findIndex(
                          (e) => e.name === "view_details_trip"
                        ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name === "delete_trip"
                          ) > -1) && (
                          <td>
                            {allPermissionsList.findIndex(
                              (e) => e.name === "view_details_trip"
                            ) > -1 && (
                              <button
                                type={`button`}
                                onClick={(e) => onDeleteScedule(e, item)}
                                className={`btn btn-circle btn-danger add-btn-circle me-2`}
                              >
                                <i className="bi bi-trash-fill"></i>
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                </>
              )}

              {ScheduleList && ScheduleList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name === "modify_trip"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name === "delete_trip"
                      ) > -1
                        ? 8
                        : 8
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`DeleteRole`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this Schedule Route?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyCompany}
            >{`Delete`}</button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ScheduleRoute;

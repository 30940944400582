import React from 'react'

const TrackingOrderList = () => {
  return (
    <div>
      TrackingOrderList
    </div>
  )
}

export default TrackingOrderList

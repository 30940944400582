import React from "react";
import Logo from "../../assets/images/Logo.png";

const DriverDiaryList = () => {
  return (
    <div>
      <section className="totalsB">
        <div className="tablePageMain mt-2">
          <div>
            <div className="logoimg">
              <img src={Logo} alt="logoimg" />
            </div>
          </div>
          <div className>
            <div className="Content">
              <div>
                <h3>Driver Diary report</h3>

                <h5 style={{ cursor: "pointer" }}>Export to Excel</h5>
              </div>
            </div>
          </div>
        </div>
        <section className="mt-3">
          <div className="tablePage">
            <div className="container-fluid">
              <div className="table-title">
                <h5>Totals</h5>
              </div>
              <div className="custom-table mt-4 table-responsive">
                <table className="table table-striped table-hover">
                  <thead className="table-light">
                    <tr>
                      <th />
                      <th colSpan={4} className="text-center">
                        Terminal - Carreira 7 &gt; Terminal - Carreira 16
                      </th>
                      <th />
                      <th colSpan={4} className="text-center">
                        Terminal - Carreira 7 &gt; Terminal - Carreira 16
                      </th>
                      <th />
                      <th colSpan={3} className="text-center">
                        Both
                      </th>
                    </tr>
                    <tr>
                      <th scope="col">Vehicle</th>
                      <th scope="col">Trip Time</th>
                      <th scope="col">GPS mileage (km)</th>
                      <th scope="col">Stopover</th>
                      <th scope="col">Numbers of trips</th>
                      <th scope="col" />
                      <th scope="col">trip time</th>
                      <th scope="col">GPS mileage (km)</th>
                      <th scope="col">Stopover</th>
                      <th scope="col">Numbers of trips</th>
                      <th />
                      <th scope="col">trip time</th>
                      <th scope="col">GPS mileage (km)</th>
                      <th scope="col">Stopover</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">131</th>
                      <td>00:37</td>
                      <td className="text-end">8</td>
                      <td className="text-end">00:08</td>
                      <td>3</td>
                      <td />
                      <td className="text-end">01.40</td>
                      <td className="text-end">23</td>
                      <td className="text-end">00:01 </td>
                      <td>2</td>
                      <td />
                      <td>02:17</td>
                      <td>31</td>
                      <td>00:08</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-3">
          <div className="tablePage">
            <div className="container-fluid">
              <div className="table-title">
                <h5>Outllers</h5>
              </div>
              <div className="custom-table mt-4 table-responsive">
                <table className="table table-striped table-hover">
                  <thead className="table-light">
                    <tr>
                      <th colSpan={14}>
                        Terminal - Carreira 7 &gt; Terminal - Carreira 14
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th colSpan={3} className="text-center">
                        Trip time{" "}
                      </th>
                      <th />
                      <th colSpan={3} className="text-center">
                        Mileage (km)
                      </th>
                      <th />
                      <th colSpan={3} className="text-center">
                        Stopover
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" />
                      <th scope="col">Shortest</th>
                      <th scope="col">Longest</th>
                      <th scope="col">Average</th>
                      <th scope="col" />
                      <th scope="col">Shortest</th>
                      <th scope="col">Longest</th>
                      <th scope="col">Average</th>
                      <th />
                      <th scope="col">Shortest</th>
                      <th scope="col">Longest</th>
                      <th scope="col">Average</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Vehicle</th>
                      <td>00:37</td>
                      <td className="text-end">8</td>
                      <td className="text-end">00:08</td>
                      <td />
                      <td />
                      <td className="text-end">01.40</td>
                      <td className="text-end">23</td>
                      <td className="text-end" />
                      <td>2</td>
                      <td />
                      <td>02:17</td>
                    </tr>
                    <tr>
                      <th scope="row">Driver</th>
                      <td>00:37</td>
                      <td className="text-end">8</td>
                      <td className="text-end">00:08</td>
                      <td />
                      <td />
                      <td className="text-end">01.40</td>
                      <td className="text-end">23</td>
                      <td className="text-end" />
                      <td>2</td>
                      <td />
                      <td>02:17</td>
                    </tr>
                    <tr>
                      <th scope="row">Start</th>
                      <td>00:37</td>
                      <td className="text-end">8</td>
                      <td className="text-end">00:08</td>
                      <td />
                      <td />
                      <td className="text-end">01.40</td>
                      <td className="text-end">23</td>
                      <td className="text-end" />
                      <td>2</td>
                      <td />
                      <td>02:17</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-striped table-hover mt-3">
                  <thead className="table-light">
                    <tr>
                      <th colSpan={14}>
                        Terminal - Carreira 7 &gt; Terminal - Carreira 14
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th colSpan={3} className="text-center">
                        Trip time{" "}
                      </th>
                      <th />
                      <th colSpan={3} className="text-center">
                        Mileage (km)
                      </th>
                      <th />
                      <th colSpan={3} className="text-center">
                        Stopover
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" />
                      <th scope="col">Shortest</th>
                      <th scope="col">Longest</th>
                      <th scope="col">Average</th>
                      <th scope="col" />
                      <th scope="col">Shortest</th>
                      <th scope="col">Longest</th>
                      <th scope="col">Average</th>
                      <th />
                      <th scope="col">Shortest</th>
                      <th scope="col">Longest</th>
                      <th scope="col">Average</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Vehicle</th>
                      <td>00:37</td>
                      <td className="text-end">8</td>
                      <td className="text-end">00:08</td>
                      <td />
                      <td />
                      <td className="text-end">01.40</td>
                      <td className="text-end">23</td>
                      <td className="text-end" />
                      <td>2</td>
                      <td />
                      <td>02:17</td>
                    </tr>
                    <tr>
                      <th scope="row">Driver</th>
                      <td>00:37</td>
                      <td className="text-end">8</td>
                      <td className="text-end">00:08</td>
                      <td />
                      <td />
                      <td className="text-end">01.40</td>
                      <td className="text-end">23</td>
                      <td className="text-end" />
                      <td>2</td>
                      <td />
                      <td>02:17</td>
                    </tr>
                    <tr>
                      <th scope="row">Start</th>
                      <td>00:37</td>
                      <td className="text-end">8</td>
                      <td className="text-end">00:08</td>
                      <td />
                      <td />
                      <td className="text-end">01.40</td>
                      <td className="text-end">23</td>
                      <td className="text-end" />
                      <td>2</td>
                      <td />
                      <td>02:17</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default DriverDiaryList;

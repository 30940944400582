import React, { useState } from "react";
import Trip from "./index";
import TemplateTrip from "./TemplateTrip";
import ScheduleRoute from "./ScheduleRoute";
import ArrivalDepartureTrip from "./ArrivalDepartureTrip";

const TripMain = () => {
  const [activeTab, setActiveTab] = useState("assigned_route");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Routes</h5>
        </div>
      </div>
      <div className="leaflet-control-management managementui-tab">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "assigned_route" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("assigned_route");
                }}
              >
                <span data-href="#tab-1">Assigned Routes</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "template" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("template");
                }}
              >
                <span data-href="#tab-1">Template</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "schedule_route" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("schedule_route");
                }}
              >
                <span data-href="#tab-1">Schedule Route</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "arrivals" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("arrivals");
                }}
              >
                <span data-href="#tab-1">Arrivals and Departures</span>
              </li>
            </ul>
          </nav>
          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "assigned_route" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <Trip />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "template" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <TemplateTrip />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "schedule_route" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <ScheduleRoute />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "arrivals" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <ArrivalDepartureTrip />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripMain;

import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import {
  actionAlarmReports,
  actionDetailsCost,
  actionFuelCostReports,
} from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";
import moment from "moment";

const DetailsCostList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("DetailsCostReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (alarmsDetails) {
      let data = {
        vehicle_id: parsedData?.vehicle_id,
        date: parsedData?.date,
        cost_type_id: parsedData.cost_type_id,
      };
      actionDetailsCost(data)
        .then((response) => {
          setAlarmsDetailsList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [alarmsDetails]);

  const exportToExcel = () => {
    if (!alarmsDetailsList || Object.keys(alarmsDetailsList).length === 0) {
      alert("No data to export!");
      return;
    }
  
    const dataToExport = [alarmsDetailsList]; 
  
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");
  
    XLSX.writeFile(workbook, "cost_details.xlsx");
  };

  return (
    <div>
      <section className="totalsB">
        <div className="tablePage">
          <div className="container-fluid">
            <div className="tablePageMain mt-2">
              <div>
                <div className="logoimg">
                  <img src={Logo} alt="logoimg" />
                </div>
              </div>
              <div className>
                <div className="Content">
                  <div>
                    <h3>Detailed costs report</h3>

                    <h5 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                      Export to Excel
                    </h5>
                  </div>
                </div>
              </div>
            </div>

                <>
                  <div className="table-title">
                    <h5>Vehicle: {alarmsDetailsList?.vehicle_name}</h5>
                  </div>
                  <div className="custom-table mt-4 table-responsive">
                    <table className="table table-striped table-hover">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">Date/Time</th>
                          <th scope="col">Cost type</th>
                          <th scope="col">Entity</th>
                          <th scope="col">Cost Description</th>
                          <th scope="col">Quntity</th>
                          <th scope="col">Unit</th>
                          <th scope="col">Unit cost(EUR)</th>
                          <th>Total cost(EUR)</th>
                          <th>Odometer(km)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moment(alarmsDetailsList?.date).format("DD-MM-YYYY HH:mm:ss")}
                          </td>
                          <td>{alarmsDetailsList?.cost_type_name}</td>
                          <td>{alarmsDetailsList?.entities_name}</td>
                          <td>{alarmsDetailsList?.cost_description}</td>
                          <td>0.00</td>
                          <td>-</td>
                          <td>0.000</td>
                          <td>{alarmsDetailsList?.total_cost}</td>
                          <td>{alarmsDetailsList?.odometer}</td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="bg-dark text-light">
                            Total
                          </td>
                          <td className="bg-dark text-light">{alarmsDetailsList?.total_cost}</td>
                          <td className="bg-dark text-light" />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailsCostList;

import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import { actionAlarmReports } from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";

const AlarmList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("alarmReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (alarmsDetails) {
      let data = {
        is_reload: parsedData?.is_reload,
        limit: parsedData?.limit,
        page: parsedData?.page,
        search: parsedData?.search,
        from_date: parsedData?.from_date,
        to_date: parsedData?.to_date,
        include_notes: parsedData?.include_notes,
        show_information_by: parsedData?.show_information_by,
        show_information_id: parsedData?.show_information_id,
        alarms: parsedData?.alarms,
      };
      actionAlarmReports(data)
        .then((response) => {
          setAlarmsDetailsList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [alarmsDetails]);

  const exportToExcel = () => {
    if (!alarmsDetailsList || alarmsDetailsList.length === 0) {
      toast.error("No data to export!");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(alarmsDetailsList);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "alarms_details.xlsx");
  };

  return (
    <div>
      <h1>Alarm Reports</h1>

      <div className="tablePage">
        <div className="container-fluid">
          <div className="tablePageMain mt-2">
            <div className>
              <div className="logoimg">
                <img src={Logo} alt="logoimg" />
              </div>
            </div>

            <div className>
              <div className="Content">
                <div>
                  <h3>ALARMS</h3>
                  <h5 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                    Export to Excel
                  </h5>
                </div>
              </div>
            </div>
          </div>
          {alarmsDetailsList?.length > 0 ? (
            <>
              <div className="custom-table mt-4">
                <table className="table table-striped table-hover">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Email</th>
                      <th scope="col">Speed</th>
                      <th scope="col">Type Name</th>
                      <th scope="col">Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alarmsDetailsList?.map((alarm) => {
                      return (
                        <tr>
                          <td>{alarm?.email}</td>
                          <td>{alarm?.over_speed_limit}</td>
                          <td>{alarm?.type_name}</td>
                          <td>{alarm?.notes}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <p>No alarm details available.</p>
            </>
          )}
        </div>
      </div>
      {/* </>
      ) : (
       
      )} */}
    </div>
  );
};

export default AlarmList;

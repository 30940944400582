import axiosInstance from "./AxiosInstance";

export function actionClassVehicle(data) {
  return axiosInstance.post(`/vehicle/class-list`, data);
}

export function actionUpdateVehicle(data) {
  return axiosInstance.post(`/vehicle/create-update`, data);
}

export function actionUpdateManagementVehicle(data) {
  return axiosInstance.post(`/vehicle/create-update-management`, data);
}

export function actionUpdateCapacityEquipmentVehicle(data) {
  return axiosInstance.post(`/vehicle/create-update-capacity-equipment`, data);
}

export function actionUpdatePrivacyModeVehicle(data) {
  return axiosInstance.post(`/vehicle/update-privacy-mode`, data);
}

export function actionUpdateStatus(data) {
  return axiosInstance.post(`/vehicle/update-vehicle-status`, data);
}

export function actionUpdateworkingPeriodVehicle(data) {
  return axiosInstance.post(`/vehicle/update-working-period`, data);
}

export function actionUpdateTachographVehicle(data) {
  return axiosInstance.post(`/vehicle/update-vehicle-tachograph`, data);
}

export function getVehicleAllDetail(data) {
  return axiosInstance.post(`/vehicle/get-all-detail`, data);
}

export function getVehicleList(data) {
  return axiosInstance.post(`/vehicle/list`, data);
}

export function getVehicleDetail(data) {
  return axiosInstance.post(`/vehicle/detail`, data);
}

export function actionDelVehicle(data) {
  return axiosInstance.post(`/vehicle/delete`, data);
}

export function getDeviceList(data) {
  return axiosInstance.post(`/device-relay/list`, data);
}

export function getVehicleHistoryDetail(data) {
  return axiosInstance.post(`/vehcile/history-details`, data);
}

export function getDeviceFilterList(data) {
  return axiosInstance.post(`device-relay-filter/list`, data);
}

export function getDeviceHelathList(data) {
  return axiosInstance.post(`/device-relay-helath/list`, data);
}

export function getDeviceHelathAllList(data) {
  return axiosInstance.post(`/device-relay-helath/all-list`, data);
}

export function getDeviceHelathKeyhList(data) {
  return axiosInstance.post(`device-relay-helath-key/list`, data);
}

export function getDeviceVehcileTripList(data) {
  return axiosInstance.post(`device-relay-vehicle-trip/list`, data);
}

export function getDeviceLastAllVehcileTripList(data) {
  return axiosInstance.post(`/device-relay-vehicle-trip-all-last/list`, data);
}
export function getDeviceVehcileAllTripList(data) {
  return axiosInstance.post(`/device-relay-vehicle-all-route-trip`, data);
}

export function getDeviceImeiList(data) {
  return axiosInstance.post(`/device-relay-helath/imei-list`, data);
}

export function getVehicleActivityList(data) {
  return axiosInstance.post(`/vehicle-activity`, data);
}

export function getVehicleNearest(data) {
  return axiosInstance.post(`/get-nearest-vehicle`, data);
}
